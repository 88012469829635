import { environment } from "src/environments/environment";
import { Microfrontend } from "../core/models/types/microfrontend.types";
import { RemoteMenuType } from "../core/models/enums/remote-menu.enum";
import { TecComModuleType, TranslationScopeType } from "shared-states";

export const PROTECTED_REMOTES_ROUTES: Microfrontend[] = [
  {
    ...environment.microfrontends.dashboard,
    exposedModule: environment.microfrontends.dashboard.exposedModule[0],

    displayName: 'Dashboard',
    displayLabel: false,
    displayIcon: 'home-05-solid',
    routePath: 'newapp/home/dashboard',
    menuType: RemoteMenuType.Default,
    menuJsonPath: 'assets/dashboard/menu.json',
    ngModuleName: 'DashboardModule',
    module: TecComModuleType.dashboard,
    legacyModule: 'teccom',
    translationScope: TranslationScopeType.dashboard
  },
  {
    ...environment.microfrontends.orders,
    exposedModule: environment.microfrontends.orders.exposedModule[0],

    displayName: 'Orders',
    displayLabel: true,
    displayIcon: 'shopping-cart-03-solid',
    routePath: 'orders',
    ngModuleName: 'OrdersModule',
    menuType: RemoteMenuType.Remote,
    menuJsonPath: 'assets/orders/menu.json',
    module: TecComModuleType.orders,
    translationScope: TranslationScopeType.orders
  },
  {
    ...environment.microfrontends.invoices,
    exposedModule: environment.microfrontends.invoices.exposedModule[0],

    displayName: 'Invoices',
    displayLabel: true,
    displayIcon: 'invoice-03-solid-custom',
    routePath: 'invoices',
    ngModuleName: 'InvoicesModule',
    menuType: RemoteMenuType.Remote,
    menuJsonPath: 'assets/invoices/menu.json',
    module: TecComModuleType.invoices,
    translationScope: TranslationScopeType.invoices
  },
  {
    ...environment.microfrontends.returns,
    exposedModule: environment.microfrontends.returns.exposedModule[0],

    displayName: 'Returns',
    displayLabel: true,
    displayIcon: 'refresh-solid-custom',
    routePath: 'returns',
    ngModuleName: 'ReturnsModule',
    menuType: RemoteMenuType.Remote,
    menuJsonPath: 'assets/returns/menu.json',
    module: TecComModuleType.returns,
    translationScope: TranslationScopeType.returns
  },
  {
    ...environment.microfrontends.articleData,
    exposedModule: environment.microfrontends.articleData.exposedModule[0],

    displayName: 'ArticleData',
    displayLabel: true,
    displayIcon: 'cloud-link-solid-custom',
    routePath: 'articledata',
    ngModuleName: 'ArticleDataModule',
    menuType: RemoteMenuType.Remote,
    menuJsonPath: 'assets/article-data/menu.json',
    i18nPath: 'assets/article-data/i18n/',
    module: TecComModuleType.articleData
  },
  {
    ...environment.microfrontends.reports,
    exposedModule: environment.microfrontends.reports.exposedModule[0],

    displayName: 'Reports',
    displayLabel: true,
    displayIcon: 'area-chart-solid-custom',
    routePath: 'reports',
    ngModuleName: 'AppModule',
    menuType: RemoteMenuType.Remote,
    menuJsonPath: 'assets/reports/menu.json',
    module: TecComModuleType.reports,
    translationScope: TranslationScopeType.reports
  },
  {
    ...environment.microfrontends.monitoring,
    exposedModule: environment.microfrontends.monitoring.exposedModule[0],

    displayName: 'Monitoring',
    displayLabel: true,
    displayIcon: 'table-solid-custom',
    routePath: 'monitoring',
    ngModuleName: 'MonitoringModule',
    menuType: RemoteMenuType.Remote,
    menuJsonPath: 'assets/monitoring/menu.json',
    module: TecComModuleType.monitoring,
    translationScope: TranslationScopeType.monitoring
  },
  {
    ...environment.microfrontends.administration,
    exposedModule: environment.microfrontends.administration.exposedModule[0],

    displayName: 'Administration',
    displayLabel: true,
    displayIcon: 'settings-02-solid',
    routePath: 'administration',
    ngModuleName: 'AdministrationModule',
    menuType: RemoteMenuType.Remote,
    menuJsonPath: 'assets/administration/menu.json',
    i18nPath: 'assets/administration/i18n/',
    module: TecComModuleType.administration
  },
  {
    ...environment.microfrontends.network,
    exposedModule: environment.microfrontends.network.exposedModule[0],

    displayName: 'TecComNetwork',
    displayLabel: true,
    displayIcon: 'link-solid-custom',
    routePath: 'network',
    ngModuleName: 'NetworkModule',
    menuType: RemoteMenuType.Remote,
    menuJsonPath: 'assets/network/menu.json',
    module: TecComModuleType.tecComNetwork,
    translationScope: TranslationScopeType.tecComNetwork
  },
  {
    ...environment.microfrontends.connect,
    exposedModule: environment.microfrontends.connect.exposedModule[0],

    displayName: 'Connect',
    displayLabel: true,
    displayIcon: 'arrow-right-left-solid-custom',
    routePath: 'connect',
    ngModuleName: 'ConnectModule',
    menuType: RemoteMenuType.Remote,
    menuJsonPath: 'assets/connect/menu.json',
    module: TecComModuleType.connect,
    translationScope: TranslationScopeType.connect
  },
  {
    ...environment.microfrontends.internal,
    exposedModule: environment.microfrontends.internal.exposedModule[0],

    displayName: 'Internal',
    displayLabel: true,
    displayIcon: 'settings-solid-custom',
    routePath: 'internal',
    ngModuleName: 'InternalModule',
    menuType: RemoteMenuType.Remote,
    menuJsonPath: 'assets/internal/menu.json',
    module: TecComModuleType.internal,
    translationScope: TranslationScopeType.internal
  },
  {
    ...environment.microfrontends.maintenance,
    exposedModule: environment.microfrontends.maintenance.exposedModule[0],

    displayName: 'Maintenance',
    displayLabel: true,
    displayIcon: 'settings-solid-custom',
    routePath: 'maintenance',
    ngModuleName: 'MaintenanceModule',
    menuType: RemoteMenuType.Remote,
    menuJsonPath: 'assets/maintenance/menu.json',
    i18nPath: 'assets/maintenance/i18n/',
    module: TecComModuleType.maintenance
  }
]
