export const environment = {
    production: true,

    // Api
    userServiceUrl: 'https://teccom-backend-userservice-staging.azurewebsites.net',
    notificationServiceUrl: 'https://teccom-backend-notificationservice-staging.azurewebsites.net',
    translationServiceUrl: 'https://teccom-backend-translationservice-staging.azurewebsites.net',
    organizationServiceUrl: 'https://teccom-backend-organizationservice-staging.azurewebsites.net',

    // Token
    allowedDomains: ['teccom-backend-organizationservice-staging.azurewebsites.net', 'teccom-backend-notificationservice-staging.azurewebsites.net', 'teccom-backend-userservice-staging.azurewebsites.net'],

    // Excluded domains for internal token
    excludedDomains: ['teccom-backend-userservice-staging.azurewebsites.net', 'teccom-backend-notificationservice-staging.azurewebsites.net'],
    
    // Okta
    oktaOptions: {
      clientId: '0oa1rn356wpb9hpSg0x7',
      issuerUri: 'https://login.qa.tecalliance.cloud/oauth2/default',
      redirectUri: `${window.location.origin}/callback`,
      postLogoutRedirectUri: 'https://teccom.staging.tecalliance.cloud/newapp/auth/welcome'
    },

    // Remotes
    microfrontends: {
      dashboard: {
        remoteUri: 'https://teccom-portal-dashboard-staging.azurewebsites.net/',
        remoteFile: 'remoteEntry.js',
        remoteName: 'dashboard',
        exposedModule: ['DashboardModule'],
      },
      orders: {
        remoteUri: 'https://teccom-portal-orders-staging.azurewebsites.net/',
        remoteFile: 'remoteEntry.js',
        remoteName: 'orders',
        exposedModule: ['OrdersModule'],
      },
      returns: {
        remoteUri: 'https://teccom-portal-returns-staging.azurewebsites.net/',
        remoteFile: 'remoteEntry.js',
        remoteName: 'returns',
        exposedModule: ['ReturnsModule', 'ReturnsPublicModule'],
      },
      invoices: {
        remoteUri: 'https://teccom-portal-invoices-staging.azurewebsites.net/',
        remoteFile: 'remoteEntry.js',
        remoteName: 'invoices',
        exposedModule: ['InvoicesModule'],
      },
      reports: {
        remoteUri: 'https://teccom-portal-reports-staging.azurewebsites.net/',
        remoteFile: 'remoteEntry.js',
        remoteName: 'reports',
        exposedModule: ['AppModule'],
      },
      monitoring: {
        remoteUri: 'https://teccom-portal-monitoring-staging.azurewebsites.net/',
        remoteFile: 'remoteEntry.js',
        remoteName: 'monitoring',
        exposedModule: ['MonitoringModule'],
      },
      administration: {
        remoteUri: 'https://teccom-portal-administration-staging.azurewebsites.net/',
        remoteFile: 'remoteEntry.js',
        remoteName: 'administration',
        exposedModule: ['AdministrationModule'],
      },
      network: {
        remoteUri: 'https://teccom-portal-network-staging.azurewebsites.net/',
        remoteFile: 'remoteEntry.js',
        remoteName: 'network',
        exposedModule: ['NetworkModule'],
      },
      connect: {
        remoteUri: 'https://teccom-portal-connect-staging.azurewebsites.net/',
        remoteFile: 'remoteEntry.js',
        remoteName: 'connect',
        exposedModule: ['ConnectModule'],
      },
      internal: {
        remoteUri: 'https://teccom-portal-internal-staging.azurewebsites.net/',
        remoteFile: 'remoteEntry.js',
        remoteName: 'internal',
        exposedModule: ['InternalModule'],
      },
      articleData: {
          remoteUri: 'https://teccom-portal-articledata-staging.azurewebsites.net/',
          remoteFile: 'remoteEntry.js',
          remoteName: 'articledata',
          exposedModule: ['ArticleDataModule']
      },
      maintenance: {
        remoteUri: 'https://teccom-portal-maintenance-staging.azurewebsites.net/',
        remoteFile: 'remoteEntry.js',
        remoteName: 'maintenance',
        exposedModule: ['MaintenanceModule']
      }
    },

    // Emails
    supportEmail: 'support@tecalliance.net',

    // Uri
    tecComWikiUri: 'https://help-teccom.tecalliance.net/en/home',
    statusPageUri: 'https://status.tecalliance.net/',
    tecallianceWebSite: 'https://www.tecalliance.net/',
    supportPageUri: 'https://ordersupportweb-staging-frontend.azurewebsites.net/',

    // TODO Remove this when migration is done
    legacyDomainSuffix: '.staging.tecalliance.cloud',
    legacyUserProfileUri: 'https://teccom.staging.tecalliance.cloud/newapp/user/profile',
    legacySubscriptionUri: 'https://teccom.staging.tecalliance.cloud/newapp/user/subscription',
    legacyOrganizationUri: 'https://teccom.staging.tecalliance.cloud/newapp/administration/organizations',
    legacyWebinarUri: 'https://teccom.staging.tecalliance.cloud/newapp/info/webinars',
    legacyReleaseNotesUri: 'https://teccom.staging.tecalliance.cloud/newapp/info/release-notes',
    legacyLegalNotice: 'https://teccom.staging.tecalliance.cloud/newapp/imprint-info'
  };
