import { Inject, Injectable } from "@angular/core";
import { State, Action, StateContext, Selector, createSelector } from "@ngxs/store";
import { ShellStateModel } from "./shell.model";
import { Shell } from "./shell.actions";
import { ThemeType } from "../../models/enums/theme.enum";
import { MenuEntry } from "src/app/modules/protected/components/menu/models/menu.model";
import { flatMapDeep } from "lodash-es";
import { TecComModuleType, User } from "shared-states";
import { OKTA_AUTH } from "@okta/okta-angular";
import OktaAuth from "@okta/okta-auth-js";
import { Router } from "@angular/router";

function getAllPaths(menuEntries: MenuEntry[]): string[] {
  return flatMapDeep(menuEntries, (menuEntry: MenuEntry) => {
    return [menuEntry.path, ...getAllPaths(menuEntry.entries)];
  });
}

@State<ShellStateModel>({
  name: "shell",
  defaults: {
    defaultMenu: [],
    userMenu: [],
    theme: ThemeType.light,
    isInitialized: false
  },
})
@Injectable()
export class ShellState {
  @Selector()
  static isInitialized(state: ShellStateModel): boolean {
    return state.isInitialized;
  }

  @Selector()
  static getStateError(state: ShellStateModel): Error | undefined {
    return state.error;
  }

  @Selector()
  static getTheme(state: ShellStateModel): ThemeType {
    return state.theme;
  }

  @Selector()
  static getUserMenu(state: ShellStateModel): MenuEntry[] {
    return state.userMenu;
  }

  @Selector()
  static getUserSubMenu(state: ShellStateModel): MenuEntry[] {
    return state.userMenu.find((menu: MenuEntry) => menu.module === state.activeModule)?.entries ?? [];
  }

  static hasPathPermission(menuPath: string): (state: ShellStateModel) => boolean {
    return createSelector([ShellState], (state: ShellStateModel) => {
      return getAllPaths(state.userMenu).filter((p: string) => p.indexOf('/') >= 0).some((path: string) => menuPath.includes(`/${path}`));
    });
  }

  static isModuleActive(module: TecComModuleType): (state: ShellStateModel) => boolean {
    return createSelector([ShellState], (state: ShellStateModel) => {
      return module === state.activeModule;
    });
  }

  constructor(
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
    private router: Router
  ) { }

  @Action(Shell.InitShellSuccessAction)
  initShellSuccessAction(
    { patchState }: StateContext<ShellStateModel>,
    { theme, menu, userMenu, error }: Shell.InitShellSuccessAction
  ): void {
    patchState({ theme: theme, defaultMenu: menu, userMenu: userMenu, isInitialized: true, error: error });
  }

  @Action(Shell.UpdateUserMenuSuccessAction)
  updateUserMenuSuccessAction(
    { patchState }: StateContext<ShellStateModel>,
    { menu }: Shell.UpdateUserMenuSuccessAction
  ): void {
    patchState({ userMenu: menu });
  }

  @Action(Shell.ChangeThemeSuccessAction)
  changeThemeSuccessAction(
    { patchState }: StateContext<ShellStateModel>,
    { theme }: Shell.ChangeThemeSuccessAction
  ): void {
    patchState({ theme: theme });
  }

  @Action(Shell.ChangeActiveModuleSuccessAction)
  changeActiveModuleSuccessAction(
    { patchState }: StateContext<ShellStateModel>,
    { module }: Shell.ChangeActiveModuleSuccessAction
  ): void {
    patchState({ activeModule: module });
  }

  @Action(User.UserSessionExpiredAction)
  processSessionExpired(
    context: StateContext<ShellStateModel>,
    action: User.UserSessionExpiredAction
  ): void {
    this.oktaAuth.clearStorage();
    this.oktaAuth.setOriginalUri(this.router.url);
    this.oktaAuth.signInWithRedirect();
  }
}
