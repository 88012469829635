import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Organization } from 'shared-states';

@Injectable({ providedIn: 'root' })
export class OrganizationService {
  constructor(private _httpClient: HttpClient) {
  }

  getOrganizations(): Observable<Organization[]> {
    return this._httpClient.get<Organization[]>(`${environment.organizationServiceUrl}/api/v1/organization`);
  }
}